<template>
  <v-container fluid class="col-lg-10" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          List Quotation Notes
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <v-card flat class="custom-grey-border remove-border-radius mt-4">
      <v-card-title class="headline grey lighten-4">
        <v-row>
          <v-col md="6" class="py-0 my-auto">
            <h3
              class="font-weight-700 custom-headline color-custom-blue"
              style="margin: 3.5px 0px"
            >
              Quotation Notes
            </h3>
          </v-col>
          <v-col md="6" class="py-0 my-auto d-flex justify-content-end">
            <!-- <v-btn
              small
              v-on:click="open_note_dialog('payment')"
              class="text-white"
              color="cyan"
              :disabled="pageLoading"
              :loading="pageLoading"
            >
              <span class="font-size-16 font-weight-600"
                >Add Quotation Notes</span
              >
            </v-btn> -->
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="p-6 font-size-16">
        <v-row>
          <v-col md12>
            <table width="100%">
              <thead>
                <tr>
                  <th align="left" class="py-2" width="3%">#</th>
                  <th align="left" width="15%" class="py-2">Type</th>
                  <th class="py-2">Label</th>
                  <th class="py-2" width="10%"></th>
                </tr>
              </thead>
              <tbody v-if="note_listing.length">
                <tr
                  v-for="(row, index) in note_listing"
                  :key="index"
                  class="border-top"
                >
                  <td align="left" class="py-2">{{ index + 1 }}</td>
                  <td align="left" class="py-2">
                    <div class="d-flex">
                      <v-chip
                        label
                        small
                        color="blue"
                        text-color="white"
                        class="text-uppercase"
                        >{{ row.type }}
                      </v-chip>
                    </div>
                  </td>

                  <td class="py-2">{{ row.label }}</td>

                  <td align="left" valign="top" class="py-2">
                    <v-btn
                      color="cyan"
                      v-on:click="edit_note_dialog(row)"
                      class="text-white"
                    >
                      <v-icon size="15">mdi-pencil</v-icon> Update
                    </v-btn>
                    <!--  <v-btn
                      class="ml-4"
                      small
                      icon
                      color="red"
                      v-on:click="deleteDbAutocompelte(row.id)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn> -->
                  </td>
                </tr>
              </tbody>
              <tfoot v-else>
                <tr class="border-top border-bottom">
                  <td colspan="5" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... Nothing Found.
                    </p>
                  </td>
                </tr>
              </tfoot>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <note-dialog :common-dialog="note_dialog" :dialog-width="dialogWidth">
      <template v-slot:title>
        <span class="text-capitalize"> Update {{ note.type }}</span>
      </template>
      <template v-slot:body>
        <v-container>
          <v-form
            ref="noteForm"
            v-model="noteForm"
            lazy-validation
            v-on:submit.stop.prevent="update_or_create_note()"
          >
            <table width="100%">
              <tr class="d-none">
                <th valign="top" width="15%" class="py-2">Type</th>
                <td class="py-2">
                  <v-radio-group
                    disabled
                    v-model="note.type"
                    row
                    mandatory
                    hide-details
                    class="mt-0"
                  >
                    <template v-for="(note_data, index) in notesType">
                      <v-radio
                        :key="index"
                        color="cyan"
                        :label="note_data.label"
                        :value="note_data.value"
                        v-on:change="getQuotationNote(note_data.value)"
                      ></v-radio>
                    </template>
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <th valign="top" class="py-2" width="10%">Label</th>

                <td class="py-2">
                  <template v-if="note.type == 'exclusions'">
                    <tiny-mce :disabled="formLoading" v-model="note.label" />
                  </template>
                  <template v-else>
                    <v-autocomplete
                      id="job-type"
                      dense
                      v-model="note.label"
                      :items="allNotes"
                      item-text="text"
                      item-color="cyan"
                      item-value="text"
                      filled
                      hide-details
                      placeholder="Label"
                      solo
                      flat
                      color="cyan"
                      :multiple="note.type === 'remark'"
                    />
                  </template>
                </td>
              </tr>
            </table>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="custom-bold-button"
          :disabled="formLoading"
          v-on:click="note_dialog = false"
          >Cancel</v-btn
        >
        <v-btn
          class="custom-bold-button"
          :disabled="formLoading"
          :loading="formLoading"
          v-on:click="update_or_create_note()"
          color="cyan white--text"
          >Save</v-btn
        >
      </template>
    </note-dialog>
  </v-container>
</template>
    
    <script>
import { GET, PATCH, DELETE } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

export default {
  name: "profile-quotation-setting",
  data() {
    return {
      pageLoading: false,
      formLoading: false,
      noteForm: true,
      note: {
        id: null,
        type: "payment",
        label: null,
      },
      notesType: [],
      allNotes: [],
      note_dialog: false,
      note_listing: [],
    };
  },
  methods: {
    open_note_dialog() {
      this.note = {
        id: null,
        type: "payment",
        label: null,
      };
      this.$nextTick(function () {
        /*  this.getQuotationNote(type); */
        this.note_dialog = true;
      });
    },

    deleteDbAutocompelte(id) {
      const _this = this;
      _this.$store
        .dispatch(DELETE, { url: `delete-quotation-notes/${id}` })
        .then(() => {
          this.getListing();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    edit_note_dialog(row) {
      this.getQuotationNote(row.type);
      this.note = {
        id: row.id,
        type: row.type,
        label: row.editLabel,
      };
      this.$nextTick(function () {
        this.note_dialog = true;
      });
    },
    update_or_create_note() {
      let _this = this;

      const vStatus = _this.$refs.noteForm.validate();

      if (!vStatus) {
        return false;
      }

      _this.formLoading = true;

      if (this.note.type == "remark") {
        this.note.label = JSON.stringify(this.note.label);
      }

      setTimeout(function () {
        _this.$store
          .dispatch(PATCH, { url: "setting/quotation-notes", data: _this.note })
          .then(({ data }) => {
            _this.note_listing = data;
            _this.note_dialog = false;
            _this.getListing();
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }, 500);
    },

    getQuotationNote(type) {
      const _this = this;

      if (type === "remark") {
        this.note.label = Array.isArray(this.note.label) ? this.note.label : [];
      } else {
        this.note.label = null; // Reset to single value
      }

      _this.$store
        .dispatch(GET, { url: `setting/quote-notes/${type}` })
        .then(({ data }) => {
          _this.notesType = data.notesType;
          _this.allNotes = data.allNotes;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getListing() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/quotation-notes" })
        .then(({ data }) => {
          _this.note_listing = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  components: {
    "note-dialog": Dialog,
    "tiny-mce": TinyMCE,
  },
  mounted() {
    this.getListing();
    this.getQuotationNote("payment");
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
  